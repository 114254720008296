<template>
  <div class="fluid" id="shiftList">
    <div>
      <div class="shift-list-div">
        <v-card elevation="0" class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h4 class="indigo--text" style="font-weight: bold; margin: auto">
                KATEGORI SHIFT
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="group-list-toolbar-1"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    padding-top: 15px;
                  "
                >
                  <div class="d-flex align-content-start">
                    <div
                      style="margin-right: 15px; height: 45px"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        style="font-size: 12px"
                        @click="addShift"
                        elevation="2"
                        tilerounded="5px"
                        color="primary"
                        class="white--text"
                        small
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Add Shift</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </div>
                  <div style="width: 210px; height: 45px">
                    <v-autocomplete
                      v-model="paramAPI.company_id"
                      label="Perusa"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="companyWatcher"
                      :items="dropdown.company"
                      :disabled="isDisableCompanyDropdown"
                    ></v-autocomplete>
                  </div>
                  <div style="width: 50%; margin-left: auto">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      @keyup.enter="searchEnter"
                      label="Search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>
            </v-col>

            <v-col cols="12" style="width: 100%">
              <v-data-table
                v-if="result.length > 0 || !skeleton_loading"
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                :page="paramAPI.page"
                @update:page="updatePage"
                :items-per-page="paramAPI.itemsPerPage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                      color: green;
                    "
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.allow_check_another_shift`]="{ item }">
                  <div
                    :style="`
                      width: 70px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                      `
                    "
                  >
                  <v-checkbox
                    v-model="item.allow_check_another_shift"
                    :true-value="true"
                    :false-value="false"
                    style=" margin:0 0 0 10px; height:25px; padding:0;"
                    readonly
                    color="grey"
                  ></v-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="
                      width: 80px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <div
                    style="
                      width: 80px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.updatedAt) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="rowClick(item)"
                        small
                        color="primary"
                        class="mr-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        color="error"
                        @click.stop="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-skeleton-loader
                v-else
                style="width: 100%"
                type="table-heading, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="shiftDialog" persistent max-width="500px">
      <v-card v-if="form !== null">
        <v-card-title>
          <span class="text-h5">Add/Edit Shift</span>
        </v-card-title>
        <v-form ref="entryForm2" @submit.prevent="saveShift">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Name
                  </p>
                  <v-text-field
                    v-model="form.name"
                    :rules="rules.nameRules"
                    dense
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perusahaan
                  </p>
                  <v-autocomplete
                    v-model="form.company"
                    :items="dropdown.company"
                    :disabled="isDisableCompanyDropdown"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0;display:flex;justify-content:center;align-items:center;"
                >
                  <div style="padding: 0 10px;width:100%;">
                    <v-col
                      cols="12"
                      style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                    >
                      <p
                        style="
                        margin:0 0 0 5px;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Check shift lain bila diluar waktu normalnya ?
                      </p>

                      <v-checkbox
                        v-model="form.allow_check_another_shift"
                        style=" margin:0 0 0 10px; height:25px; padding:0;"
                      ></v-checkbox>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeShiftDialog">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'mastershift',
  data: () => ({
    editDialog: false,
    shiftDialog: false,
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null
      // department_id: null
    },
    totalData: 0,
    form: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Allow Offset',
        value: 'allow_check_another_shift',
        align: 'left',
        sortable: false
      },
      {
        text: 'Created at',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'updated at',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    isDisableCompanyDropdown: false,
    rules: {
      nameRules: [],
      companyRules: []
    },
    dropdown: {
      company: []
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    if (this.getShiftSettingPageQuery !== null) {
      this.paramAPI = this.getShiftSettingPageQuery
    }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
    }, 200)
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getShiftSettingPageQuery'])
  },
  watch: {},
  methods: {
    // ...mapActions(['dropdownPlant']),
    ...mapMutations(['setShiftSettingPageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    companyWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async dropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '39'
      )
      let xFlag = false
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            xFlag = true
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          xFlag = true
        }
      } else {
        xFlag = true
      }

      if (xFlag) {
        if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
          this.isDisableCompanyDropdown = true
        } else {
          this.isDisableCompanyDropdown = false
        }
        axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then((res) => {
            this.dropdown.company = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}master/universal/shiftcategory/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&join_table=`
      const filter = []
      // if (this.paramAPI.company_id !== null) {
      //   filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
      // }
      url = url + `&filter=[${filter}]`
      this.setShiftSettingPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    enableEdit() {
      var oldForm = this.form
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
        this.form = oldForm
        if (this.form.act === 'add') {
          this.closeDialog()
        }
      }
    },

    rowClick(pItem) {
      let xCompany = null
      if (pItem.company_id !== null) {
        xCompany = {
          id: pItem.company_id,
          name: pItem.company_name
        }
      }
      this.form = {
        act: 'update',
        id: pItem.id,
        name: pItem.name,
        company: xCompany,
        allow_check_another_shift: pItem.allow_check_another_shift
      }
      setTimeout(() => {
        this.shiftDialog = !this.shiftDialog
      }, 300)
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.hrsApi}master/universal/shiftcategory/delete/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    closeDialog() {
      this.form = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    addShift() {
      this.form = {
        act: 'add',
        name: null,
        company: null,
        allow_check_another_shift: false
      }
      setTimeout(() => {
        this.shiftDialog = !this.shiftDialog
      }, 300)
    },
    saveShift() {
      this.rules.nameRules = [(v) => !!v || 'Name is required']
      // this.rules.companyRules = [(v) => !!v || 'Company is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm2.validate()) {
          const form = {
            act: self.form.act,
            name: self.form.name,
            company_id: self.form.company != null ? self.form.company.id : null,
            company_name: self.form.company != null ? self.form.company.name : null,
            allow_check_another_shift: self.allow_check_another_shift
          }
          if (form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          self.submitShift(form)
        }
      })
    },
    async submitShift(form) {
      console.log(form)
      await axios
        .post(`${this.hrsApi}master/universal/shiftcategory/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeShiftDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeShiftDialog() {
      this.form = null
      setTimeout(() => {
        this.shiftDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#shiftList {
  position: relative;
  .shift-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .group-list-toolbar-1 {
      .group-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #shiftList {
    .shift-list-div {
      .group-list-toolbar-1 {
        .group-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
